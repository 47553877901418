:host {
  display: block;
}

.fnd-info-block {
  align-items: left;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 2px 0;
  white-space: nowrap;

  &-label {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    margin-right: 12px;
    padding-right: 10px;
    white-space: nowrap;
  }

  &:after {
    background-color: #aaa;
    bottom: 0;
    content: '';
    height: 2.25em;
    padding-top: 8px;
    position: relative;
    right: -12px;
    width: 1px;
  }

  &:last-child:after {
    background-color: transparent;
  }

}

.fnd-info-inner-block {
  display: flex;
  flex-direction: column;
}

.fnd-info-inner-block-children {
  display: flex;
  align-items: center;
  width: auto;
  overflow: auto;
  text-wrap: wrap;
}