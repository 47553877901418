.task-filter {
  width: 300px;
}

.filter-bar{
  display: flex;
  flex-direction: row;
  width: '100%';
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 24px;
  z-index: 10000;
}

.timeline-info-block-outer {
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
}

.timeline-info-block {
  display: flex; 
  flex-direction: row; 
  align-items: center;
  gap: 8px;
}