.navbar {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
    padding-bottom: 0
  }

.links {
    /* margin-left: var(--mantine-spacing-md); */
    margin-right: var(--mantine-spacing-md)
}

.linksInner {
    padding-top: var(--mantine-spacing-lg);
    padding-bottom: var(--mantine-spacing-lg)
}