.header {
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  padding-left: 16px;
  z-index: 6px;
  position: fixed;
  background-color: #001C4C;
  color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-7));
  border-bottom: 1px solid;
  border-color: light-dark(var(--mantine-color-titan-6), var(--mantine-color-dark-5));
  display: flex;
  /* align-items: center; */
  justify-content: space-between
}

.leftSection {
  position: absolute;
  top: -1px;
  right: 0px;
  bottom: 0px;
  left: 16px;
  display: flex;
  align-items: left;
  justify-content: left;
}
.rightSection {
  flex: 1;
  margin-left: var(--mantine-spacing-xl);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--mantine-spacing-md);
}