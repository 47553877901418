.root {
    position: 'relative';
    cursor: 'pointer';
  }
  .icon {
    pointer-events: 'none';
    position: 'absolute';
    z-index: 1;
    top: 3;
  }
  .labelOn {
    left: 4;
    color: var(--mantine-color-white);
  }
  .labelOff {
    right: 4;
    color: var(--mantine-color-gray-7);
  }