:host {
  display: block;
}

.fnd-expandable {
  overflow: hidden;

  &.--animated {
    transition: height 0.2s ease-out;
  }
}
