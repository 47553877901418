.mantine-DatePicker-calendarHeader{
    display: flex;
    max-width: calc(16.1875rem);
    margin-bottom: 0.625rem;
}
.mantine-DatePicker-calendarHeaderControlIcon[data-direction="next"]{ 
    width: 1rem;
    height: 1rem;
    transform: rotate(270deg);
}
.mantine-DatePicker-calendarHeaderControlIcon[data-direction="previous"]{ 
    width: 1rem;
    height: 1rem;
    transform: rotate(90deg);
}
.mantine-DatePicker-day[data-weekend]{
    color: #FA5252 !important;
}

.relative .mantine-DatePicker-day[data-in-range]{
    background-color: var(--mantine-color-blue-1);
    color: black;
}
.relative .mantine-DatePicker-day[data-first-in-range]{
    background-color: var(--mantine-color-blue-7);
    color: var(--mantine-color-green-6);
}
.relative .mantine-DatePicker-day[data-last-in-range]{
    background-color: var(--mantine-color-blue-7);
    color: white;
}

.exact .mantine-DatePicker-day[data-in-range]{
    background-color: var(--mantine-color-violet-2);
    color: black;
}
.exact .mantine-DatePicker-day[data-first-in-range]{
    background-color: var(--mantine-color-violet-8);
    color: var(--mantine-color-green-6);
}
.exact .mantine-DatePicker-day[data-last-in-range]{
    background-color: var(--mantine-color-violet-8);
    color: white;
}

.switchTrack {
    border: 1px var(--mantine-color-gray-6) solid;
    background-color: var(--mantine-color-violet-7);
  }
.arrow {
    border-top: 1px var(--mantine-color-gray-6) solid !important;
    border-left: 1px var(--mantine-color-gray-6) solid !important;
    border-bottom: transparent !important;
    border-right: transparent !important;
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-7)) ;
}  
.dropdown {
    border: 1px var(--mantine-color-gray-6) solid !important;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}