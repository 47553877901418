.listSortable {
    border: 1px solid #f0f3f5;
    border-radius: var(--mantine-radius-sm);
    margin-bottom: var(--mantine-radius-sm);
    padding: 6;
    padding-left: 12;
  }
  .ghost {
    opacity: 0.5;
    background: var(--mantine-color-blue-3);
  }
  .chosen {
    color: white;
    background: var(--mantine-color-blue-2);
  }
  .handle {
    cursor: '-webkit-grabbing';
  }