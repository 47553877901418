.arrow {
    border-top: 1px var(--mantine-color-gray-6) solid !important;
    border-right: 1px var(--mantine-color-gray-6) solid !important;
    border-bottom: transparent !important;
    border-left: transparent !important;
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-7)) ;
}  
.dropdown {
    border: 1px var(--mantine-color-gray-6) solid !important;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}