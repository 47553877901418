.selectInput {
  width: 115px;
  border-radius: 6px;
  background-color: light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
  border: 1px solid #b8d1ff !important;
}

.mantineInputDark  .mantine-Input-input {
    background-color: #001C4C;
    border-color: #001C4C;
}

.mantineInputLight .mantine-Input-input {
    background-color: #E6EFFF;
    border-color: #B8D2FD;
}

.input {
    width: 170px;
    height: 20px;
    font-size: .9em
}

.inputdd {
  border-radius: 6 !important;
  /* border: 1px solid #b8d1ff !important; */
  border: 1px solid #B8D2FD !important;
  background-color: light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
}

.multiselectInput {
  outline: 1px solid var(--mantine-color-wbdblue-0) !important;
  background-color: light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
}
.item {
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1)) !important;
}