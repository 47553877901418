body {
  bottom: 0px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Open Sans, sans-serif;
  font-size: 14px !important;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.base {
  height: height - 124;
  width: width;
  margin-top: -6
}

.baseBox {
  border: '1px solid';
  border-radius: var(--mantine-radius-md);
  padding: 8px;
  height: 100%
}

.baseContainer {
  border-radius: var(--mantine-radius-md);
  padding: 8px !important;
  max-width: 100%
}

.widgetContainer {
  background-color: light-dark(var(--mantine-color-dark-6), var(--mantine-color-titan-0));
  border: '1px solid';
  border-color: #5c5c61;
  border-radius: var(--mantine-radius-md);
  padding: 8px;
  margin: -4px;
  height: 100%
}

.detail {
  background-color: light-dark(var(--mantine-color-titan-0), var(--mantine-color-dark-6));
  border: '1px solid';
  border-color: #5c5c61;
  border-radius: var(--mantine-radius-sm)
}

.item {
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1)) !important;

}

.input {
  border: 1px solid #b8d1ff;
  border-radius: 6px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}

.popoverArrow {
  border: 1px var(--mantine-color-gray-6) solid;
  background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-7));
}

.popoverDropdownBelow {
  border: 1px var(--mantine-color-gray-6) solid;
  background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-gray-7));
  margin-top: -18px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}

.popoverDropdown {
  border: 1px var(--mantine-color-gray-6) solid;
  background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-gray-7));
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}

.wrapper {
  /* border: `1px solid ${
      theme.colorScheme === 'dark'
        ? '#253F65'
        : theme.fn.lighten(colors.wbdblue[3], 0.6)
    }`, */
  border: 1px solid #253F65;
  border-radius: 18px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}
.wrapperLight {
  border: 1px solid #b8d1ff;
  border-radius: 18px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}
.wrapperDark {
  border: 1px solid rgb(38, 62, 103);
  border-radius: 18px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1));
}

.accordionLabel {
  padding: 0
}

.themebtn-bg-color:hover {
  background-color:#99CCFF;
}
  @media (max-width: 36em) {
    .mediaQueryuse {
      width:'12.5rem';
      min-width:'12.5rem' 
    }
  }

.inputdd {
    border-radius: 6 !important;
    border: 1px solid #b8d1ff !important;
    background-color: light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
  };

.dateRangebgColor {
  background-color: rgb(0, 102, 204) !important;
  border-radius: 2rem !important;
}

.multiselect {
  border: 1px solid #B8D2FD !important;
  background-color: light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
}

.inputDark {
  border: 1px solid rgb(38, 62, 103) !important;
  background-color: light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
}
.howTo{
  padding-top: 0px !important ;
}
.mantine-Select-input:focus {
  border-color: rgb(0, 128, 255) !important;
}
.mantine-MultiSelect-input:focus-within{
  border-color: rgb(0, 128, 255) !important;
}
