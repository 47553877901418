.wrapper {
    width: 130px;
}

.input {
    border-radius: 6 !important;
    border: 1px solid #b8d1ff ;
    background-color: var(--mantine-color-wbdblue-0);
};

.inputDark {
    border-radius: 6 !important;
    border: 1px solid #b8d1ff !important;
    background-color: var(--mantine-color-dark-6) !important;
}

