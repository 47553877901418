.detailLight {
    background-color: var(--mantine-color-gray-0) !important; 
    border: 1px solid;
    border-color: var(--mantine-color-gray-3) !important;
    border-radius: var(--mantine-radius-md);
    padding: 8px;
    height: 100%;
  }

  .detailDark {
    background-color: var(--mantine-color-dark-6) !important; 
    border: 1px solid;
    border-color: var(--mantine-color-dark-4) !important;
    border-radius: var(--mantine-radius-md);
    padding: 8px;
    height: 100%;
  }