.ag-ltr .ag-cell{   
    display: flex;
   justify-content: left;
   -webkit-box-align: center;
   align-items: center;
 }
 .viewDark .ag-header-cell {
    background-color: var(--mantine-color-wbdblue-7);
 }
 .viewLight .ag-header-cell {
    background-color: var(--mantine-color-wbdblue-0);
 }
 .viewLight .ag-root-wrapper{
    border-color: #B8D2FD;
 }

 .viewDark .ag-root-wrapper{
    border-color: black;
 }
 .viewLight .ag-side-bar{
    background-color: var(--mantine-color-wbdblue-0);
 }

 .viewDark .ag-side-bar{
    background-color: var(--mantine-color-wbdblue-7);
 }
 
.deleted {
   color: #c53037;
}