.item {
    color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-gray-7));
}

.wrapper {
    border: 1px solid light-dark('#68686e', var(--mantinecolor-gray-6));
    border-radius: 18;
    color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-gray-7));
}
.input {
    border-radius: 6 !important;
    border: 1px solid #b8d1ff !important;
    background-color: light-dark(var(--mantine-color-wbdblue-0), var(--mantine-color-dark-6)) !important;
};

.arrowLight {
    border: 1px var(--mantine-color-gray-6) solid !important; 
    background-color: var(--mantine-color-gray-1) !important;
  }
.arrowDark {
    border: 1px var(--mantine-color-gray-6) solid !important;
    background-color: var(--mantine-color-dark-7) !important
  }
.dropdownDark {
    border: 1px var(--mantine-color-gray-6) solid !important;
    background-color: var(--mantine-color-dark-7) !important;
    top: 10.7vh !important;
    left: 60.3vw !important;
    right: 17vw !important;
    bottom: 35vh !important;
    /* width: 100% !important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 24vw !important;
}
.dropdownLight {
    border: 1px var(--mantine-color-gray-6) solid !important;
    background-color: var(--mantine-color-gray-1) !important;
    top: 10.7vh !important;
    left: 60.3vw !important;
    right: 17vw !important;
    bottom: 35vh !important;
    /* width: 100% !important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 24vw !important;
}