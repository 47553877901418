.control {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-sm);
  margin-bottom: 1em;
}
.controlDark {
  color: var(--mantine-color-dark-0);
}

.controlDark:hover {
  background-color: var(--mantine-color-dark-9);
  color: var(--mantine-color-white);
}

.controlLight {
  color: var(--mantine-color-black);
}

.controlLight:hover {
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-black);
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: 31;
  margin-left: 30;
  font-size: var(--mantine-font-size-sm);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

}
.linkLight {
  color: var(--mantine-color-gray-7);
  border-color: var(--mantine-color-gray-3);
}
.linkDark{
  color: var(--mantine-color-dark-0);
  border-color: var(--mantine-color-dark-4);
}
.link:hover {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.chevron {
  transition: transform 200ms ease
}