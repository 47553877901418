:host {
  display: block;
}

.fnd-info-panel {
  &-content {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    padding: 0px 8px 8px 8px;
    width: calc(100% - 16px);
  }
}

.fnd-info-bar {
  align-items: center;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin: 0;
  transition-duration: 0.4s;
  
  &:hover {
    filter: brightness('85%');
  }

  &-left {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 12px;
    min-width: 250px;
  }
  &-middle {
    width: auto;
    display: flex;
    flex-wrap: wrap;
  }
  &-right {
    margin-left: auto
  }
}

.fnd-info-bar-item {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  border-left: 1px solid #888;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &-label {
    color: #888;
    font-size: 12px;
    font-weight: 400;
    margin-right: 12px;
    padding-right: 10px;
    white-space: nowrap;
  }
  &-right {
    border: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 110px;
    font-size: 14px;
    font-weight: 600;
    margin-left: auto
  }
  &-left {
    border: 0px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    
  }
}


.chevron {
  cursor: pointer;
  transition-duration: 0.4s;
}

.chevron-down {
  transform: rotate(90deg);
  transition-duration: 0.4s;
}

.processBreadcrumb {
  font-size: 11px;
  color: #888;
  margin-top: 6px;
}
