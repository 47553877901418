.root {
    position: relative;
    /* '& *': {
      cursor: pointer;
    } */
  }

  .icon {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 3
  }

  .iconLight {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 3;
    left: 4;
    color: var(--mantine-color-white);
  }

  .iconDark {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 3;
    right: 4;
    color: var(--mantine-color-gray-6)
  }
