.aggrid {
    .ag-header-cell {
        background-color: #e6eefb
        /* theme.colorScheme === 'dark' ? theme.colors.wbdblue[9] : '#e6eefb' */
    }
    .ag-root-wrapper {
        border-color: #b8d2fd
        /* theme.colorScheme === 'dark' ? theme.fn.darken('#b8d2fd', 2.5) : '#b8d2fd' */
    }
    .ag-side-bar {
        background-color: #e6eefb
        /* theme.colorScheme === 'dark' ? theme.colors.wbdblue[9] : '#e6eefb' */
    }
    .ag-header-cell-label {
        display: flex;
        justify-content: space-around;
        align-items: center
    }
    .ag-row .ag-cell {
        display: flex;
        justify-content: left; /* align horizontal */
        align-items: 'center'; /*   align vertical   */
    }
    .deleted {
        color: #c53037
    }
    .expired {
        color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-gray-7))
        /* theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[5], */
    }
}
